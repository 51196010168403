import { useState } from 'react';
import { Button } from 'antd';

import CardConnectEmail from '../CardAuth/CardConnectEmail';
import CardCreateAccount from '../CardAuth/CardCreateAccount';
import CardForgotPassword from '../CardAuth/CardForgotPassword';
import CardLogin from '../CardAuth/CardLogin';
import CardSignUp from '../CardAuth/CardSignUp';
import ImageC from '../Image';
import ModalC, { ModalCProps } from '../Modal';

import classes from './index.module.less';

interface Props extends ModalCProps {
	type?: 'signup' | 'create-account' | 'login' | 'forgot-password' | 'connect-email';
	onClose?: (e?: any) => void;
}

const ModalAuth = ({ open, type: pType = 'signup', ...props }: Props) => {
	const [type, setType] = useState<Props['type']>(pType);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onClose = () => {
		setType('signup');
		props.onClose?.();
	};

	const onHandleBackToSignUp = () => {
		if (type === 'forgot-password') {
			setType('login');
		} else {
			setType('signup');
		}
	};

	return (
		<ModalC
			className={classes.modal}
			open={open}
			onClose={type === 'connect-email' ? props.onClose : onClose}
			centered
			showCloseIcon={false}
			closeOnOverlayClick={isLoading || type === 'create-account' ? false : true}
		>
			<div className={classes['wrap-auth']}>
				{type === 'signup' && (
					<CardSignUp
						onCreateAccountClick={() => setType('create-account')}
						onLoginClick={() => setType('login')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'create-account' && (
					<CardCreateAccount
						onClose={() => setType('signup')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'login' && (
					<CardLogin
						onForgotPasswordClick={() => setType('forgot-password')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'forgot-password' && (
					<CardForgotPassword onSuccess={onClose} updateLoadingState={setIsLoading} />
				)}
				{type === 'connect-email' && (
					<CardConnectEmail
						onClose={props.onClose}
						onSuccess={props.onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type !== 'signup' && !['connect-email'].includes(`${type || '-'}`) && (
					<Button
						className={classes['button-back']}
						icon={<ImageC src="/icons/left-white.svg" alt="icon back" width={16} />}
						shape="circle"
						onClick={onHandleBackToSignUp}
					/>
				)}
			</div>
		</ModalC>
	);
};

export default ModalAuth;
